const size = {
    mobile: '750px',
    desktop: '751px'
}

const device = {
    mobile: `@media (max-width: ${size.mobile})`,
    desktop: `@media (min-width: ${size.desktop})`
}

export default device

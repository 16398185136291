import styled from 'styled-components'

export default function Button({
    href,
    square,
    block,
    type,
    color,
    download,
    children,
    onClick,
    external,
    dark,
    light,
    ghost,
    disabled
}) {
    if (external) {
        return (
            <Container
                block={block}
                dark={dark}
                color={color}
                light={light}
                ghost={ghost}
                disabled={disabled}
                as="a"
                href={href}
                rel="noreferrer noopener"
                target="_blank"
                download={download}
                onClick={onClick}
            >
                <span data-name={children}>{children}</span>
            </Container>
        )
    }

    if (href) {
        return (
            <Container
                block={block}
                dark={dark}
                ghost={ghost}
                color={color}
                light={light}
                disabled={disabled}
                as="a"
                href={href}
                onClick={onClick}
            >
                <span data-name={children}>{children}</span>
            </Container>
        )
    }

    if (onClick) {
        return (
            <Container
                block={block}
                dark={dark}
                ghost={ghost}
                light={light}
                color={color}
                disabled={disabled}
                as="button"
                type={type}
                onClick={onClick}
            >
                <span data-name={children}>{children}</span>
            </Container>
        )
    }

    return (
        <Container
            square={square}
            block={block}
            dark={dark}
            ghost={ghost}
            color={color}
            light={light}
            disabled={disabled}
            as="button"
            type={type}
        >
            <span data-name={children}>{children}</span>
        </Container>
    )
}

const Container = styled.div`
    padding: 0;
    span {
        color: ${props => {
            if (props.color) return 'var(--color-dark) !important'
            if (props.light || props.ghost) return 'var(--color-dark)'
            return '#fff'
        }};
    }
    background: ${props => {
        if (props.color) return `var(--color-${props.color})`
        return props.ghost ? 'transparent' : 'var(--color-dark)'
    }};
    display: ${props => (props.block ? 'block' : 'inline-block')};
    outline: 0;
    width: ${props => (props.block ? '100%' : 'auto')};
    user-select: none;
    border: 0;
    font-family: 'Archivo';
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    height: ${props => (props.square ? 0.44 : 0.48)}rem;
    border-radius: ${props => (props.square ? 0.12 : 0.24)}rem;
    font-size: 0.16rem;
    font-weight: 600;
    transform: scale(1);
    text-decoration: none !important;
    ${props => (props.disabled ? 'opacity: .3;' : '')}
    ${props => (props.disabled ? 'pointer-events: none;' : '')}
    span {
        position: relative;
        padding: 0 0.36rem;
        text-align: center;
        height: ${props => (props.square ? 0.44 : 0.48)}rem;
        line-height: ${props => (props.square ? 0.44 : 0.48)}rem;
        display: block;
        transition: transform 300ms ease-in-out;
        &::after {
            content: attr(data-name);
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 100%;
        }
    }
    &:hover span {
        transform: translate3d(0, -100%, 0);
    }
    &:after {
        ${props =>
            props.ghost &&
            `content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border-radius: 24rem;
        border: 1px solid var(--color-border);`}
    }
`

import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import UniversalCookies from 'universal-cookie'
import useTranslation from 'next-translate/useTranslation'

import Button from 'components/Button'
import Svg from 'components/Svg'
import device from 'utils/device'

export default function Cookies() {
    const { t } = useTranslation('common')

    const cookies = new UniversalCookies()
    const vhCookie = cookies.get('vhCookie')
    const [showCookie, setShowCookie] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showInfo1, setShowInfo1] = useState(false)
    const [showInfo2, setShowInfo2] = useState(false)
    const [showInfo3, setShowInfo3] = useState(false)

    useEffect(() => {
        setShowCookie(!vhCookie)
    }, [vhCookie])

    const essentialHandler = useCallback(() => {
        document.querySelector('html').classList.remove('noscroll')
        cookies.set(
            'vhCookie',
            {
                analytical: 0,
                advertising: 0,
                functional: 1
            },
            {
                path: '/',
                expires: new Date(Date.now() + 31536000000)
            }
        )
        setShowCookie(false)
    }, [])

    const acceptHandler = useCallback(() => {
        document.querySelector('html').classList.remove('noscroll')
        cookies.set(
            'vhCookie',
            {
                analytical: 1,
                advertising: 1,
                functional: 1
            },
            {
                path: '/',
                expires: new Date(Date.now() + 31536000000)
            }
        )
        setShowCookie(false)
    })

    const manageHandler = useCallback(() => {
        document.querySelector('html').classList.add('noscroll')
        setShowModal(true)
    })

    const hideModalHandler = useCallback(() => {
        document.querySelector('html').classList.remove('noscroll')
        setShowModal(false)
    })

    const toggleHandler1 = useCallback(() => {
        setShowInfo1(state => !state)
    })

    const toggleHandler2 = useCallback(() => {
        setShowInfo2(state => !state)
    })

    const toggleHandler3 = useCallback(() => {
        setShowInfo3(state => !state)
    })

    if (!showCookie) return null

    return (
        <>
            <Modal show={showModal}>
                <ModalBg onClick={hideModalHandler} />
                <Center>
                    <Close onClick={hideModalHandler}>
                        <Svg name="x" />
                    </Close>
                    <Block onClick={toggleHandler1}>
                        <Name active={showInfo1}>
                            <b>
                                {t('cookies_modal_block_1_name')}
                                <Svg name="caret" />
                            </b>
                            <span>{t('cookies_modal_always_on')}</span>
                        </Name>
                        <Description show={showInfo1}>{t('cookies_modal_block_1_description')}</Description>
                    </Block>
                    <Block onClick={toggleHandler2}>
                        <Name active={showInfo2}>
                            <b>
                                {t('cookies_modal_block_2_name')}
                                <Svg name="caret" />
                            </b>
                        </Name>
                        <Description show={showInfo2}>{t('cookies_modal_block_2_description')}</Description>
                    </Block>
                    <Block onClick={toggleHandler3}>
                        <Name active={showInfo3}>
                            <b>
                                {t('cookies_modal_block_3_name')}
                                <Svg name="caret" />
                            </b>
                        </Name>
                        <Description show={showInfo3}>{t('cookies_modal_block_3_description')}</Description>
                    </Block>
                    <Buttons>
                        <Button onClick={essentialHandler} ghost>
                            {t('cookies_modal_accept_essential')}
                        </Button>
                        <Button onClick={acceptHandler}>{t('cookies_modal_accept_all')}</Button>
                    </Buttons>
                </Center>
            </Modal>
            <Container>
                <Content>
                    {t('cookies_bar_1')}. <i />
                    <Link href="/about/cookie-policy">{t('cookies_bar_2')}</Link>
                    <span> • </span>
                    <Manage onClick={manageHandler}>{t('cookies_bar_3')}</Manage>
                    <Accept onClick={acceptHandler}>{t('cookies_bar_4')}</Accept>
                </Content>
            </Container>
        </>
    )
}

const Container = styled.div`
    position: fixed;
    text-align: center;
    bottom: 0.36rem;
    z-index: 11;
    left: 0;
    width: 100%;
    pointer-events: none;
    padding: 0 0.16rem;
`

const Content = styled.div`
    pointer-events: all;
    color: var(--color-white);
    background: var(--color-dark);
    border-radius: 0.2rem;
    font-size: 0.16rem;
    display: inline-block;
    padding: 0.2rem 0.36rem;
    span {
        color: var(--color-blue);
    }
    a {
        color: var(--color-blue);
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    ${device.mobile} {
        width: 100%;
        display: block;
        i {
            display: block;
        }
    }
`

const Manage = styled.span`
    text-decoration: underline;
    cursor: pointer;
    color: var(--color-blue);
    &:hover {
        text-decoration: none;
    }
`

const Accept = styled.span`
    cursor: pointer;
    user-select: none;
    margin-left: 0.36rem;
    font-weight: 600;
    background: var(--color-white);
    padding: 0.12rem;
    border-radius: 0.12rem;
    color: var(--color-dark) !important;
    ${device.mobile} {
        display: block;
        margin: 0.24rem 0 0 0;
    }
`
// color: var(--color-blue);
// text-decoration: underline;
// &:hover {
//     text-decoration: none;
// }

const Modal = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    transition: opacity 300ms ease-in-out;
    ${props => {
        if (!props.show) {
            return `
                opacity: 0;
                pointer-events: none;
            `
        }
    }}
    ${device.mobile} {
        background: #fff;
        align-items: flex-start;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
`

const ModalBg = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
`

const Center = styled.div`
    background: #fff;
    width: 7.92rem;
    padding: 0.24rem 1.02rem;
    border-radius: 0.2rem;
    position: relative;
    ${device.mobile} {
        width: 100%;
        padding: 0.48rem 0.18rem 0.18rem 0.18rem;
        border-radius: 0;
    }
`

const Block = styled.div`
    padding: 0.24rem 0;
    cursor: pointer;
    border-bottom: 1px solid var(--color-border);
`

const Name = styled.div`
    font-size: 0.22rem;
    font-weight: 600;
    color: var(--color-dark);
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
        position: absolute;
        width: 0.24rem;
        height: 0.24rem;
        left: 100%;
        margin-left: 0.04rem;
        transition: transform 300ms ease-in-out;
        transform: rotate(${props => (props.active ? 270 : 90)}deg);
    }
    b {
        display: flex;
        align-items: center;
        position: relative;
    }
    span {
        font-size: 0.16rem;
        font-weight: 400;
        color: var(--color-green);
    }
`

const Description = styled.div`
    padding-right: 20%;
    transition: max-height 300ms ease-in-out;
    max-height: ${props => (props.show ? 10 : 0)}em;
    padding-top: 0.12rem;
    overflow: hidden;
    ${device.mobile} {
        padding-right: 0;
    }
`

const Buttons = styled.div`
    display: flex;
    padding-top: 0.24rem;
    padding-bottom: 0.24rem;
    justify-content: flex-end;
    > * {
        margin-left: 0.18rem;
    }
    ${device.mobile} {
        display: block;
        padding-top: 0.12rem;
        > * {
            margin: 0.12rem 0 0 0;
            width: 100%;
        }
    }
`

const Close = styled.div`
    display: none;
    ${device.mobile} {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 0.54rem;
        height: 0.54rem;
        svg {
            width: 0.3rem;
            height: 0.3rem;
        }
    }
`
